import RequestDispatcher from "../RequestDispatcher";

export default class AuthDispatcher extends RequestDispatcher {
    private baseUrl: string = this.apiBaseUrl + 'user/';

    async signup(body: any): Promise<string> {
        const url = this.baseUrl + 'sign-up/';
        return await this.axiosPost(url, body)
    }

    async forgotPassword(body: any): Promise<void> {
        const url = this.baseUrl + 'forgot-password/';
        return await this.post(url, body)
    }

    async getMe() {
        const url = this.baseUrl + 'me/';
        return await this.read(url)
    }
}
