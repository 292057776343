import styles from './index.module.css'
import MenuCard from "../../../components/card";
import Button from "@mui/material/Button";
import {paths} from "../../paths";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Menu} from "../../../interfaces/menu";
import MenuDispatcher from "../../../utils/Dispatchers/Menu/Desk/MenuDispatcher";
import {toast} from "react-toastify";

export default function MenusDeskPage() {
    const navigate = useNavigate();
    const [menus, setMenus] = useState<Menu[]>([]);
    const menuDispatcher = new MenuDispatcher();

    useEffect(() => {
        getMenus();
    }, []);


    function getMenus() {
        menuDispatcher.getMenus().then(
            (data) => {
                setMenus(data)
                console.log(menus)
                return menus
            },
            err => {
                toast.warning('Ocorreu um erro. Por favor, recarregue a página e tente novamente.')
            }
        )
    }

    return (
        <section className={styles.menusContainer}>
            <div className={styles.menuActions}>
                {/*{broken && <div><BsLayoutTextSidebarReverse size={24}/></div>}*/}
                <h1>Cardápios de Mesa</h1>
                <div>
                    <Button style={{fontFamily: 'Poppins', backgroundColor: "#da291c"}} variant="contained"
                             onClick={() => {
                                 console.log("aa")
                                 navigate(paths.createDeskMenu);
                             }}> Criar cardápio </Button>
                </div>
            </div>
            <div className={styles.menusList}>
                {
                    menus?.length <= 0 &&
                        <div><p><strong>Bem vindo!</strong> Disponibilize agora o cardápio QR Code para seus clientes clicando no botão <strong>"Criar cardápio"</strong>.</p></div>
                }
                {
                    menus?.length > 0 && menus?.map((menu: Menu, i) => {
                        return <MenuCard key={i} menu={menu}></MenuCard>
                    })
                }
            </div>
        </section>
    );
};
