import React, {useEffect, useState} from 'react';
import {Menu, menuClasses, MenuItem, MenuItemStyles, Sidebar as SidebarComponent, SubMenu} from "react-pro-sidebar";
import {BarChart} from "./icons/BarChart";
import {Diamond} from "./icons/Diamond";
import {Service} from "./icons/Service";
import {Book} from "./icons/Book";
import {Outlet, useNavigate} from "react-router-dom";
import styles from './index.module.css'
import {paths} from "../../Pages/paths";
import {IoMdSettings} from "react-icons/io";
import {toast} from "react-toastify";
import {BsLayoutTextSidebarReverse} from "react-icons/bs";
import {useBillingInfoStore, useCompanyStore, useUserStore} from "../../zustand.store";
import AuthDispatcher from "../../utils/Dispatchers/AuthDispatcher";

type Theme = 'light' | 'dark';

const themes = {
    light: {
        sidebar: {
            backgroundColor: '#ffffff',
            color: '#607489',
        },
        menu: {
            menuContent: '#fbfcfd',
            // icon: '#0098e5',
            icon: '#da291c',
            hover: {
                backgroundColor: '#c5e4ff',
                color: '#44596e',
            },
            disabled: {
                color: '#9fb6cf',
            },
        },
    },
    dark: {
        sidebar: {
            backgroundColor: '#0b2948',
            color: '#8ba1b7',
        },
        menu: {
            menuContent: '#082440',
            icon: '#59d0ff',
            hover: {
                backgroundColor: '#00458b',
                color: '#b6c8d9',
            },
            disabled: {
                color: '#3e5e7e',
            },
        },
    },
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export default function Sidebar() {
    const [hidden, setHidden] = React.useState(false);
    const [toggled, setToggled] = React.useState(false);
    const [broken, setBroken] = React.useState(false);
    const [rtl, setRtl] = React.useState(false);
    const [hasImage, setHasImage] = React.useState(false);
    const [theme, setTheme] = React.useState<Theme>('light');
    const navigate = useNavigate()
    const { loggedUser, updateUser } = useUserStore();
    const { loggedCompany, updateCompany } = useCompanyStore();
    const { updateBillingInfo } = useBillingInfoStore();
    const authDispatcher: AuthDispatcher = new AuthDispatcher();

    useEffect(() => {
        getMe()
    }, []);

    async function getMe() {
        await authDispatcher.getMe()
            .then(
                (data) => {
                    if (!data)
                        return
                    updateUser(data)
                    updateCompany(data['company'])
                    updateBillingInfo(data['company']['billing_info'])
                }
            ).catch(e => console.log(e))
    }

    function goTo(to: string) {
        if(isDevice)
            setHidden(true)
        return navigate(to);
    }

    // handle on RTL change event
    const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRtl(e.target.checked);
    };

    // handle on theme change event
    const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTheme(e.target.checked ? 'dark' : 'light');
    };

    // handle on image change event
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHasImage(e.target.checked);
    };

    const menuItemStyles: MenuItemStyles = {
        root: {
            fontSize: '13px',
            fontWeight: 400,
        },
        icon: {
            color: themes[theme].menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: '#b6b7b9',
        },

        button: {
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
                color: themes[theme].menu.hover.color,
            },
        },
        label: () => ({
            fontWeight: 600,
        }),
    };
    const [isCollapsed, setIsCollapsed] = useState(window.innerWidth <= 768);
    const [isDevice, setIsDevice] = useState(window.innerWidth <= 500)

    useEffect(() => {
        function updateSize() {
            fixSidebar(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        window.addEventListener('load', updateSize);
        updateSize();
    }, []);


    function fixSidebar(innerWidth: number) {
        const isDevice = innerWidth <= 500;
        console.log(isDevice)
        setIsDevice(isDevice);
        setHidden(isDevice);
        setIsCollapsed(innerWidth <= 768);
    }


    return (
        <main style={{display: "flex", justifyContent: 'center'}}>
        {isDevice && <BsLayoutTextSidebarReverse className={styles.sidebarToggle} onClick={() => {
            setIsCollapsed(true);
            setHidden(!hidden);
        }} size={24}/>}
        <aside className={styles.sidebarContainer}
               style={{direction: rtl ? 'rtl' : 'ltr', display: hidden ? 'none': 'initial'}}>
            <SidebarComponent
                collapsed={isCollapsed}
                toggled={toggled}
                onBackdropClick={() => setToggled(false)}
                rtl={rtl}
                backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
                rootStyles={{
                    color: themes[theme].sidebar.color,
                }}
            >
                <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <div style={{flex: 1, marginBottom: '32px', gap: 24, display: "flex", flexDirection: 'column'}}>
                        <Menu menuItemStyles={menuItemStyles}>
                            <SubMenu
                                label="Cardápios"
                                icon={<Book/>}
                            >
                                <MenuItem onClick={() => goTo(paths.deskMenu)}> Cardápios de mesa </MenuItem>
                                <MenuItem disabled={true}> Cardápios inteligentes </MenuItem>
                            </SubMenu>
                            <SubMenu label="Relatórios" disabled={true} icon={<BarChart/>}>
                                <MenuItem> Grid</MenuItem>
                            </SubMenu>
                            <MenuItem onClick={() => goTo(paths.plans)} icon={<Diamond/>}>
                                Planos
                            </MenuItem>
                        </Menu>

                        <Menu menuItemStyles={menuItemStyles}>
                            <SubMenu label="Configurações" icon={<IoMdSettings size={22}/>}>
                                <MenuItem onClick={() => goTo(paths.company + loggedCompany.token + '/')}>
                                    Organização
                                </MenuItem>
                                <MenuItem onClick={() => goTo(paths.user + loggedUser.token + '/')}>
                                    Minha conta
                                </MenuItem>
                            </SubMenu>
                            <MenuItem icon={<Service size={20}/>}
                                      onClick={() => toast.info("Entre em contato pelo email xptoolscenter@gmail.com")}>Help
                                center</MenuItem>
                        </Menu>
                    </div>
                </div>
            </SidebarComponent>
        </aside>
        <main className={styles.contentContainer}>
            <Outlet/>
        </main>
        </main>
    );
};


{/*<main>*/
}
{/*    <div style={{padding: '16px 24px', color: '#44596e'}}>*/
}
{/*        <div style={{marginBottom: '16px'}}>*/
}
{/*            {broken && (*/
}
{/*                <button className="sb-button" onClick={() => setToggled(!toggled)}>*/
}
{/*                    Toggle*/
}
{/*                </button>*/
}
{/*            )}*/
}
{/*        </div>*/
}
{/*        <div style={{marginBottom: '48px'}}>*/
}
{/*            <Typography variant="h4" fontWeight={600}>*/
}
{/*                React Pro Sidebar*/
}
{/*            </Typography>*/
}
{/*            <Typography variant="body2">*/
}
{/*                React Pro Sidebar provides a set of components for creating high level and*/
}
{/*                customizable side navigation*/
}
{/*            </Typography>*/
}
{/*            <PackageBadges/>*/
}
{/*        </div>*/
}

{/*        <div style={{padding: '0 8px'}}>*/
}
{/*            <div style={{marginBottom: 16}}>*/
}
{/*                <Switch*/
}
{/*                    id="collapse"*/
}
{/*                    checked={collapsed}*/
}
{/*                    onChange={() => setCollapsed(!collapsed)}*/
}
{/*                    label="Collapse"*/
}
{/*                />*/
}
{/*            </div>*/
}

{/*            <div style={{marginBottom: 16}}>*/
}
{/*                <Switch id="rtl" checked={rtl} onChange={handleRTLChange} label="RTL"/>*/
}
{/*            </div>*/
}

{/*            <div style={{marginBottom: 16}}>*/
}
{/*                <Switch*/
}
{/*                    id="theme"*/
}
{/*                    checked={theme === 'dark'}*/
}
{/*                    onChange={handleThemeChange}*/
}
{/*                    label="Dark theme"*/
}
{/*                />*/
}
{/*            </div>*/
}

{/*            <div style={{marginBottom: 16}}>*/
}
{/*                <Switch id="image" checked={hasImage} onChange={handleImageChange} label="Image"/>*/
}
{/*            </div>*/
}
{/*        </div>*/
}
{/*    </div>*/
}
{/*</main>*/
}