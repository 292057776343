import React, {useState} from "react";
import Header from "../../components/header/index.tsx";
import {CustomForm} from "../../components/CustomForm";
import {paths} from "../paths";
import {Form} from "../../components/CustomForm/models";
import {InputForm} from "../../components/input/models";

export default function RedefinePassword() {
    const form: Form = {
        name: 'RedefinePassword',
        title: 'Redefinir senha',
        description: 'Por favor insira uma nova senha.',
        fields: [
            {name: 'password', type: 'password', label: 'Nova senha', placeholder: '••••••••', value: undefined},
            {name: 'confirm_password', type: 'password', label: 'Confirmar senha', placeholder: '••••••••', value: undefined},
        ],
        actionLabel: "Lembrou sua senha?",
        actionRedirect: paths.login
    };
    const [RedefinePasswordForm, setRedefinePasswordForm] = useState(form);


    function doRedefinePassword() {
        let formObject: any = {};
        RedefinePasswordForm.fields.forEach((item: InputForm) => {
            formObject[item.name] = item.value;
        })
        console.log(formObject)
        return formObject
    }

    return (
        <>
            <CustomForm form={RedefinePasswordForm} formAction={doRedefinePassword}></CustomForm>
        </>
    )
}