import RequestDispatcher from "../../../RequestDispatcher";

export default class MenuDispatcher extends RequestDispatcher {
    baseUrl: string = this.apiBaseUrl + 'menus/'

    async create(body: any): Promise<void> {
        const url = this.baseUrl + 'create/';
        return await this.post(url, body)
    }

    async remove(token: string): Promise<void> {
        const url = this.baseUrl + token + '/delete/';
        return await this.delete(url)
    }

    async changeActive(token: string, active: boolean): Promise<void> {
        const url = this.baseUrl + token + '/active/';
        const body = {active: active}
        return await this.post(url, body)
    }

    async getMenus() {
        return await this.read(this.baseUrl)
    }

    async getMenu(token: string) {
        return await this.read(this.baseUrl + token + '/')
    }
}
