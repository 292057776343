import React from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';

// Páginas
import SignUp from "./Pages/SignUp";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import RedefinePassword from "./Pages/RedefinePassword";
import MenusDeskPage from "./Pages/Cardapios/Mesa";
import CreateDeskMenuPage from "./Pages/Cardapios/Mesa/Create";
import PlansPage from "./Pages/Plans";
import CompanyPage from "./Pages/Company";
import UserPage from "./Pages/User";
import SingleMenuPage from "./Pages/SingleMenu";

// Componentes
import Header from "./components/header/index.tsx";
import Sidebar from "./components/sidebar";

// Utilitários
import PrivateRoute from "./utils/PrivateRoutes";
import { paths } from "./Pages/paths";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={paths.login} />} />
            <Route element={<Header />}>
                <Route path={paths.signUp} element={<SignUp />} />
                <Route path={paths.login} element={<Login />} />
                <Route path={paths.forgotPassword} element={<ForgotPassword />} />
                <Route path={paths.redefinePassword} element={<RedefinePassword />} />
                <Route element={<Sidebar />}>
                    <Route path="/home/*" element={<PrivateRoute Component={Login} />} />
                    <Route path={paths.home} element={<Navigate to={paths.deskMenu} />} />
                    <Route path={paths.deskMenu} element={<PrivateRoute Component={MenusDeskPage} />} />
                    <Route path={`${paths.deskMenu}:token`} element={<PrivateRoute Component={SingleMenuPage} />} />
                    <Route path={paths.createDeskMenu} element={<PrivateRoute Component={CreateDeskMenuPage} />} />
                    <Route path={paths.plans} element={<PrivateRoute Component={PlansPage} />} />
                    <Route path={`${paths.company}:token`} element={<PrivateRoute Component={CompanyPage} />} />
                    <Route path={`${paths.user}:token`} element={<PrivateRoute Component={UserPage} />} />
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
