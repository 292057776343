import styles from './index.module.css'
import * as React from "react";
import Button from "@mui/material/Button";
import {FaCheckCircle} from "react-icons/fa";


export default function PlansPage() {

    return (
        <section className={styles.contentContainer}>
            <div className={styles.cardContainer}>
                <h1>Planos e preços</h1>
                <div className={styles.cardContent}>
                    <div className={styles.cardPlan}>
                        <div>
                            <p style={{fontSize: 14}}>Equipe Starter</p>
                            <h1 style={{margin: 0}}>Plano Gratuito</h1>
                            <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', padding: 5}}>
                                <h1 style={{fontWeight: 'normal'}}>Grátis</h1>
                            </div>
                        </div>
                        <section>
                            <span><strong>Recursos</strong></span>
                            <div style={{display: "flex", alignItems: 'center', gap: 6}}>
                                <FaCheckCircle size={14}/>
                                <p>Cardápio de mesa</p>
                            </div>
                            <div style={{display: "flex", alignItems: 'center', gap: 6}}>
                                <FaCheckCircle size={14}/>
                                <p>1 QR Code</p><p style={{color: "#717171"}}>(7 dias)</p>
                            </div>
                        </section>
                    </div>
                    <div className={styles.cardPlan}>
                        <div>
                            <p style={{fontSize: 14}}>Organização</p>
                            <h1 style={{margin: 0}}>Plano Premium</h1>
                            <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', padding: 5}}>
                                <div style={{height: '100%', padding: "18px 0"}}>
                                    <div style={{display: "flex", alignItems: 'center', flexWrap: 'wrap', placeContent: 'center'}}>
                                        <p style={{color: "#717171"}}>R$</p>
                                        <h1 style={{fontWeight: 'normal', margin: 0}}>4,99</h1>
                                        <p style={{color: "#717171"}}>/mês</p>
                                    </div>
                                    <p style={{color: "#717171"}}>ou R$50 no plano anual</p>
                                </div>
                                <Button fullWidth={true} size="large"
                                        style={{fontFamily: 'Poppins', backgroundColor: "#da291c"}} variant="contained"
                                        onClick={() => {
                                            console.log("aa")
                                        }}> Assinar </Button>
                            </div>
                        </div>
                        <section>
                            <span><strong>Recursos</strong></span>
                            <div style={{display: "flex", alignItems: 'center', gap: 6}}>
                                <FaCheckCircle size={14}/>
                                <p>Cardápios ilimitados</p>
                            </div>
                            <div style={{display: "flex", alignItems: 'center', gap: 6}}>
                                <FaCheckCircle size={14}/>
                                <p>QR Codes ilimitados</p>
                            </div>
                            <div style={{display: "flex", alignItems: 'center', gap: 6}}>
                                <FaCheckCircle size={14}/>
                                <p>Customização</p>
                            </div>
                            <div style={{display: "flex", alignItems: 'center', gap: 6}}>
                                <FaCheckCircle size={14}/>
                                <p>Suporte técnico</p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
};
