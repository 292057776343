import styles from './index.module.css'
import {useEffect, useState} from "react";
import {CustomForm} from "../../components/CustomForm";
import {Form} from "../../components/CustomForm/models";
import {formToObject} from "../../utils/Json";
import CompanyDispatcher from "../../utils/Dispatchers/CompanyDispatcher";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useBillingInfoStore, useCompanyStore} from "../../zustand.store";


export default function CompanyPage() {
    const companyDispatcher = new CompanyDispatcher()
    const {token} = useParams()
    const { loggedCompany , updateCompany, clearCompany } = useCompanyStore();
    const [company, setCompany] = useState(loggedCompany);

    const { storedBillingInfo , updateBillingInfo } = useBillingInfoStore();
    const [billingInfo, setBillingInfo] = useState(storedBillingInfo);

    useEffect(() => {
        getCompany();
    }, []);

    async function getCompany() {
        // return newCompany();
        await companyDispatcher.get(token)
            .then(
                data => {
                    if (!data)
                        return
                    setCompany(data);
                    updateCompany(data);
                    updateBillingInfo(data['billing_info']);
                    setBillingInfo(data['billing_info'])
                },
                err => {
                    toast.warning('Ocorreu um erro. Por favor recarregue a página e tente novamente.')
                }
            )
    };

    const form: Form = {
        name: 'organization',
        fields: [
            {name: 'icon', type: 'icon', value: company.logo_url},
            {name: 'name', type: 'text', label: 'Nome do estabelecimento', value: company.name},
            {name: 'cnpj', type: 'text', label: 'CNPJ', value: company.cnpj},
            {name: 'description', type: 'text', label: 'Descrição', value: company.description},
            {name: 'cep', type: 'text', label: 'CEP', value: billingInfo?.cep},
            {name: 'address', type: 'text', label: 'Endereço', value: billingInfo?.address},
            {name: 'complement', type: 'text', label: 'Complemento', value: billingInfo?.complement},
        ],
        buttonLabel: 'Salvar alterações'
    };


    const changeCompany = () => {
        if (!token)
            return
        let body: any = formToObject(form);
        companyDispatcher.update(token, body)
            .then(
                data => {
                    toast.success('Organização atualizada com sucesso.')
                    setCompany(data);
                    updateCompany(data);
                    updateBillingInfo(data['billing_info'])
                    setBillingInfo(data['billing_info'])
                },
                err => {
                    toast.warning('Ocorreu um erro. Por favor, revise os campos e tente novamente.')
                }
        );
    }


    return (
        <section className={styles.contentContainer}>
            <div className={styles.cardContainer}>
                <h1>Organização</h1>
                <CustomForm form={form} formAction={changeCompany}></CustomForm>
            </div>
        </section>
    );
};
