import React, {useState} from "react";
import {CustomForm} from "../../components/CustomForm";
import {paths} from "../paths";
import {Form} from "../../components/CustomForm/models";
import AuthDispatcher from "../../utils/Dispatchers/AuthDispatcher";
import {useNavigate} from "react-router-dom";
import {formToObject} from "../../utils/Json";
import {toast} from "react-toastify";

export default function SignUp() {
    const form: Form = {
        name: 'SignUpForm',
        title: 'Nova conta',
        fields: [
            {name: 'first_name', type: 'text', label: 'Nome', placeholder: 'Nome', value: undefined},
            {name: 'last_name', type: 'text', label: 'Sobrenome', placeholder: 'Sobrenome', value: undefined},
            {name: 'email', type: 'text', label: 'Email', placeholder: 'Seu email', value: undefined},
            {name: 'phone_number', type: 'text', label: 'Telefone', placeholder: '+55', value: undefined},
            {name: 'password', type: 'password', label: 'Senha', placeholder: '••••••••', value: undefined},
            {
                name: 'confirm_password', type: 'password', label: 'Confirmar senha', placeholder: '••••••••',
                value: undefined
            },
            {
                name: 'privacy_police',
                type: 'checkbox',
                label: 'Eu aceito os termos e a política de privacidade',
                placeholder: undefined,
                value: undefined
            },
            {
                name: 'receive_offers',
                type: 'checkbox',
                label: 'Eu desejo receber emails com novidades',
                placeholder: undefined,
                value: undefined
            },
        ],
        actionLabel: "Já possui conta?",
        actionRedirect: paths.login
    };
    const [signUpForm, setSignUpForm] = useState(form);
    const authDispatcher: AuthDispatcher = new AuthDispatcher();
    const navigate = useNavigate();

    async function signUp() {
        let body: any = formToObject(signUpForm);
        await authDispatcher.signup(body)
            .then(
                data => {
                    console.log("login data: ", data)
                    toast.success(data);
                    getTokens();
                    return data
                },
                err => {
                    toast.warning(err?.response?.data);
                }
            ).catch(error => {
                console.log(error);
            });
    }


    async function getTokens() {
        let body: any = formToObject(signUpForm);
        await authDispatcher.createAccessToken(body)
            .then(
                data => {
                    authDispatcher.setTokens(data)
                    navigate(paths.home)
                })
            .catch(error => {
                toast.warning('Ocorreu um erro. Por favor, realize o login novamente.')
                navigate(paths.login)
            });
    }

    async function doSignUp() {
        await signUp();
    }

    return (
        <>
            <CustomForm form={signUpForm} formAction={doSignUp}></CustomForm>
        </>
    )
}