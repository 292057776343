import React, {useState} from "react";
import {CustomForm} from "../../components/CustomForm";
import {useNavigate} from "react-router-dom";
import {paths} from "../paths";
import {Form} from "../../components/CustomForm/models";
import AuthDispatcher from "../../utils/Dispatchers/AuthDispatcher";
import {formToObject} from "../../utils/Json";
import {toast} from "react-toastify";

export default function Login() {
    const form: Form = {
        name: 'LoginForm',
        title: 'Entrar',
        fields: [
            {name: 'email', type: 'text', label: 'Email', placeholder: 'email', value: undefined},
            {
                name: 'password', type: 'password', label: 'Senha', placeholder: '••••••••', value: undefined,
                actionLabel: 'Esqueceu sua senha?', actionRedirect: paths.forgotPassword
            },
        ],
        actionLabel: "Não possui conta?",
        actionRedirect: paths.signUp
    };
    const [loginForm, setLoginForm] = useState(form);
    const authDispatcher: AuthDispatcher = new AuthDispatcher();

    let navigate = useNavigate();

    async function createAccessToken() {
        let body: any = formToObject(loginForm);
        await authDispatcher.createAccessToken(body)
            .then(
                (response: any) => {
                    authDispatcher.setTokens(response);
                    navigate(paths.home);
                },
                err => {
                    toast.warning('Por favor verifique os dados informados.')
                }
            ).catch(
                error => {
                    toast.warning('Não foi possível identificar a conta. Por favor, tente novamente.')
                }
            );
    }

    async function doLogin() {
        await createAccessToken();
    }

    return (
        <>
            <CustomForm form={loginForm} formAction={doLogin}></CustomForm>
        </>
    )
}