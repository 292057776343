import {create} from 'zustand'
import {UserState} from "./utils/zustand/models/user";
import {CompanyState} from "./utils/zustand/models/company";
import {BillingInfoState} from "./utils/zustand/models/billingInfo";

function emptyBillingInfo() {
    return {
        id: 0,
        cep: "",
        address: "",
        complement: "",
    };
}

function emptyCompany() {
    return {
        billing_info: emptyBillingInfo(),
        id: 0,
        token: "",
        description: "",
        logo_url: "",
        name: "",
        cnpj: "",
        cpf: "",
        created_at: new Date()
    };
}

function emptyUser() {
    return {
        id: 0,
        token: "",
        first_name: "",
        last_name: "",
        company: emptyCompany(),
        email: "",
        joined_at: new Date(),
        photo_url: "",
        password: "",
        phone_number: "",
    };
}

// Criando a store do Zustand para o usuário
export const useUserStore = create<UserState>((set) => ({
    loggedUser: {...emptyUser()},
    updateUser: (newUser) => set({loggedUser: newUser}),
    clearUser: () => set({loggedUser: {...emptyUser()}}),
}));

export const useCompanyStore = create<CompanyState>((set) => ({
    loggedCompany: {...emptyCompany()},
    updateCompany: (newCompany) => set({loggedCompany: newCompany}),
    clearCompany: () => set({loggedCompany: {...emptyCompany()}}),
}));

export const useBillingInfoStore = create<BillingInfoState>((set) => ({
    storedBillingInfo: {...emptyBillingInfo()},
    updateBillingInfo: (newBillingInfo) => set({storedBillingInfo: newBillingInfo}),
    clearBillingInfo: () => set({storedBillingInfo: {...emptyBillingInfo()}}),
}));