import React, {useEffect, useState} from "react";
import RequestDispatcher from "../Dispatchers/RequestDispatcher";
import {paths} from "../../Pages/paths";
import {useNavigate, Navigate} from "react-router-dom";

const PrivateRoute = (props: any) => {

    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const dispatcher = new RequestDispatcher();
    const navigator = useNavigate();

    async function verifyAuth() {
        console.log("verificando")
        await dispatcher.verifyAccessTokenIfNeeded();
        const access = dispatcher.getAccessToken();
        const refresh = dispatcher.getRefreshToken();
        const shouldRefresh = dispatcher.shouldRefreshToken()
        const isValid = access && refresh && !shouldRefresh;
        if (!isValid) {
            navigator(paths.login);
        }
        setIsAuthenticated(isValid);
    }

    // Your authentication logic goes here...
    // useEffect(() => {
    //     const fourMinutes = 4.5 * 60 * 60;
    //     setInterval(verifyAuth, fourMinutes)
    // }, []);

    return isAuthenticated ? <props.Component/> : <Navigate to="/login"/>;
};
export default PrivateRoute;
