import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import {IoMdShare} from "react-icons/io";
import {CiCalendar} from "react-icons/ci";
import {useNavigate} from "react-router-dom";
import {paths} from "../../Pages/paths";
import styles from './index.module.css';
import {Menu} from "../../interfaces/menu";
import {toast} from "react-toastify";


export default function MenuCard(props: {menu: Menu}) {
    const navigate = useNavigate();
    const menu = props.menu;

    function getIframeSrc() {
        return menu.file_url + '#toolbar=0&navpanes=0';
    }

    function share() {
        navigator.clipboard.writeText(menu.file_url);
        toast.success('Copiado para a área de transferência.')
    }

    return (
        <Card className={styles.cardContainer}>
            <CardActionArea onClick={() => navigate(paths.deskMenu + menu.token)}>
                <div style={{border: 0, height: 150, overflow: "hidden"}}>
                    <iframe loading={"lazy"} style={{border: 0}} autoFocus={true} title={"iframe"} src={getIframeSrc()}></iframe>
                </div>

                <CardContent>
                    <h1 style={{fontSize: 16}}>
                        {menu.name}
                    </h1>
                    <div style={{display: 'flex', textAlign: 'center', gap: 6}}>
                        <CiCalendar size={18}/>
                        <p style={{alignItems: 'center', fontSize: 12}}>{new Date(menu.created_at)?.toLocaleDateString()}</p>
                    </div>
                </CardContent>
            </CardActionArea>
            <CardActions style={{placeContent: "end"}}>
                <Button style={{color: "#da291c"}} size="large" color="primary">
                <IoMdShare onClick={share}/>
                </Button>
            </CardActions>
        </Card>
    );
}
