import RequestDispatcher from "../RequestDispatcher";

export default class UserDispatcher extends RequestDispatcher {
    baseUrl: string = this.apiBaseUrl + 'user/'

    async update(token: string, body: any) {
        const url = this.baseUrl + token + '/update/';
        return this.post(url, body)
    }

    async get(token: any) {
        const url = this.baseUrl + token + '/';
        return await this.read(url)
    }

}
