import React from "react";
import styles from "./index.module.css"
import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function Header() {
    return (
        <>
            <header className={styles.AppHeader}>
                <div className={styles.LogoContainer}>
                    <h1 style={{fontWeight: 'bold', fontSize: '24px'}}>Cardapp.io</h1>
                </div>
            </header>
            <Outlet/>
            <ToastContainer></ToastContainer>
        </>
    )
}