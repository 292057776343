import React, {useState} from "react";
import {CustomForm} from "../../components/CustomForm";
import {paths} from "../paths";
import {Form} from "../../components/CustomForm/models";
import {formToObject} from "../../utils/Json";
import AuthDispatcher from "../../utils/Dispatchers/AuthDispatcher";
import {useNavigate} from "react-router-dom";

export default function ForgotPassword() {
    const form: Form = {
        name: 'ForgotPassword',
        title: 'Esqueceu sua senha?',
        description: 'Não se preocupe! Isso acontece. Por favor insira o email associado com a sua conta.',
        fields: [
            {name: 'email', type: 'text', label: 'Email', placeholder: 'Email', value: undefined}
        ],
        actionLabel: "Lembrou sua senha?",
        actionRedirect: paths.login
    };
    const [forgotPasswordForm, setForgotPasswordForm] = useState(form);
    const authDispatcher: AuthDispatcher = new AuthDispatcher();
    const navigate = useNavigate();


    function SendEmailForgotPassword() {
        let body: any = formToObject(forgotPasswordForm);
        authDispatcher.forgotPassword(body)
            .then(
                data => {
                    return data
                }
            ).catch(
            error => {
                return error
            }
        );
    }

    return (
        <>
            <CustomForm form={forgotPasswordForm} formAction={SendEmailForgotPassword}></CustomForm>
        </>
    )
}