const home = '/home/';
const menus = home + 'menus/';
const deskMenu = menus + 'desk/';
const settings = home + 'settings/';
export const paths = {
    signUp: '/sign-up/',
    login: '/login/',
    forgotPassword: '/forgot-password/',
    redefinePassword: '/redefine-password/',
    home: home,
    menus: menus,
    deskMenu: deskMenu,
    createDeskMenu: deskMenu + 'new/',
    smartMenu: menus + 'smart/',
    plans: home + 'plans/',
    settings: settings,
    company: settings + 'company/',
    user: settings + 'user/',
}