import RequestDispatcher from "../RequestDispatcher";

export default class CompanyDispatcher extends RequestDispatcher {
    baseUrl: string = this.apiBaseUrl + 'company/'

    async update(token: string, body: any) {
        const url: string = this.baseUrl + token + '/update/';
        return await this.post(url, body)
    }

    async get(token: any) {
        const url: string = this.baseUrl + token + '/';
        return await this.read(url)
    }
}
