import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import Dispatcher from "../Dispatcher";
import {paths} from "../../../Pages/paths";
import {useNavigate} from "react-router-dom";


export default class RequestDispatcher extends Dispatcher {
    loadingAccessToken = false;
    navigate = useNavigate();

    async createAccessToken(body: any) {
        const url = this.apiBaseUrl + 'auth/token/';
        return await this.axiosPost(url, body)
    }

    async verifyAccessToken() {
        this.loadingAccessToken = true;
        const url = this.apiBaseUrl + 'auth/token/verify/';
        const accessToken = await this.getRefreshToken();
        const body = {
            'token': accessToken ? accessToken : ''
        }
        return await this.post(url, body)
            .then(
                response => {
                    this.setLastRefreshToken(new Date())
                    this.setAccessToken(response['access']);
                },
                err => {
                    const fiveYears = new Date(60 * 60 * 24 * 365 * 5).getTime();
                    const now = new Date().getTime();
                    const fiveYearsAgo = new Date(now - fiveYears);
                    this.setLastRefreshToken(fiveYearsAgo )
                    this.setAccessToken('');
                    this.setRefreshToken('');
                    this.navigate(paths.login);
                }
            ).finally(() => this.loadingAccessToken = false)
    }


    setAccessToken(access: string) {
        localStorage.setItem('access', JSON.stringify(access));
    }

    getAccessToken() {
        const item = localStorage.getItem('access');
        return item ? JSON.parse(item) : '';
    }

    getRefreshToken() {
        const item = localStorage.getItem('refresh');
        return item ? JSON.parse(item) : '';
    }

    setRefreshToken(refresh: string) {
        localStorage.setItem('refresh', JSON.stringify(refresh));
    }

    setLastRefreshToken(date: Date) {
        localStorage.setItem('last_refresh_token', JSON.stringify(date.getTime()))
    }

    setTokens(response: any) {
        this.setLastRefreshToken(new Date());
        this.setAccessToken(response['access']);
        this.setRefreshToken(response['refresh']);
    }

    getLastRefreshToken() {
        const lastRefresh = localStorage.getItem('last_refresh_token');
        return new Date(parseInt(lastRefresh ? lastRefresh : '', 10))
    }

    shouldRefreshToken() {
        const lastRefresh = new Date(this.getLastRefreshToken()).getTime() / 1000;
        const currentTime = new Date().getTime() / 1000;
        const fiveMinutes = 5 * 60;
        const minutesAfterRefresh = currentTime - lastRefresh;
        return minutesAfterRefresh >= fiveMinutes;
    }

    async post(url: string, body?: any, headers: any = {}) {
        const options = this.getHeaders(headers);
        await this.verifyAccessTokenIfNeeded();
        return await this.axiosPost(url, body, options);
    }

    private getHeaders(options: any = {}) {
        const accessToken = this.getAccessToken();
        return {
            headers: {
                ...options,
                Authorization: `Bearer ${accessToken ? accessToken : ''}`
            }
        };
    }

// async read(url: string, options?: AxiosRequestConfig): Promise<void> {
    async read(url: string) {
        await this.verifyAccessTokenIfNeeded();
        const options = this.getHeaders()
        return await axios.get(url, options)
            .then((response: AxiosResponse) => {
                return this.handleSuccess(response);
            })
            .catch((error: any) => {
                return this.handleError(error);
            });
    }

    async delete(url: string) {
        await this.verifyAccessTokenIfNeeded();
        await axios.delete(url, this.getHeaders())
            .then((response: AxiosResponse) => {
                return this.handleSuccess(response);
            })
            .catch((error: any) => {
                return this.handleError(error);
            });
    }

    async verifyAccessTokenIfNeeded() {
        if (this.loadingAccessToken)
            return
        // console.log(!this.shouldRefreshToken())
        if (!this.shouldRefreshToken())
            return
        await this.verifyAccessToken();
    }

    async uploadFile(file: any) {
        var formData = new FormData();
        formData.append("file", file);
        const url = this.apiBaseUrl + 'upload/pdf/';
        return await this.post(url, formData, {
            'Content-Type': 'multipart/form-data'
        })
    }
}

