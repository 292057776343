import axios, {AxiosError, AxiosResponse} from 'axios';
import {paths} from "../../../Pages/paths";
import {useNavigate} from "react-router-dom";

export default class Dispatcher {
    apiBaseUrl: string = 'http://localhost:8000/';
    navigate = useNavigate();


    handleError(error: AxiosError<any>) {
        console.log("error");
        console.log(error);
        if (error.status == 401)
            this.navigate(paths.login)
        throw error;
    }

    handleSuccess(success: AxiosResponse<any>) {
        console.log("success")
        console.log(success)
        return success.data
    }

    async axiosPost(url: string, body: any, options: any = {}) {
        console.log("options")
        console.log(options)
        return await axios.post(url, body, options)
            .then((response: AxiosResponse) => {
                return this.handleSuccess(response);
            })
            .catch((error: any) => {
                return this.handleError(error);
            });
    }
}
