import Input from "../input";
import Button from "../button";
import React, {useState, useEffect} from "react";
import {Form} from "./models";


export function CustomForm(props: { form: Form, formAction: any }) {
    // const [form, setForm] = useState(props.form);
    let form = props.form;
    const formAction: any = props.formAction;
    const buttonLabel = props.form.buttonLabel || "Entrar";

    return (
        <section style={{display: "flex", placeContent: 'center', padding: '1%'}}>
            <div style={{
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                gap: 24,
                width: 'min-content'
            }}>
                <div>
                    <h1 style={{alignSelf: 'start', color: 'black'}}>{form.title}</h1>
                    {form.description && (
                        <p style={{fontSize: 14, color: '#717171'}}>{form.description}</p>)}
                </div>
                <div style={{display: "flex", flexDirection: 'column', gap: 24}}>
                    <form name={form.name} method={"POST"}
                          style={{
                              display: "flex",
                              flexDirection: 'column',
                              gap: '18px',
                              justifyContent: "space-around"
                          }}
                          onSubmit={(e) => {
                              console.log(e)
                              e.preventDefault();
                          }}>
                        {
                            form.fields.map((field, i) => {
                                return <Input key={i} field={field}></Input>
                            })
                        }
                    </form>
                    {formAction && <Button action={formAction} label={buttonLabel}></Button>}
                </div>

                {form.actionLabel && <div style={{
                    display: "flex", flexDirection: 'row', alignItems: 'center', gap: 6,
                    fontSize: 12
                }}>
                    <p>{form.actionLabel}</p>
                    <a href={form.actionRedirect}
                       style={{fontWeight: 'bold', textDecoration: "underline", color: 'black'}}>Entrar</a>
                </div>}
            </div>
        </section>
    );
}