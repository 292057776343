import React, {useState, useEffect} from "react";
import styles from './index.module.css'
import {FaCheckCircle, FaRegCircle} from "react-icons/fa";
import {IoMdCloudDone} from "react-icons/io";
import Button from "@mui/material/Button";
import {IoMdCloudUpload} from "react-icons/io";
import styled from "@emotion/styled";
import {Avatar} from "@mui/material";
import {InputInterface} from "./models";
import RequestDispatcher from "../../utils/Dispatchers/RequestDispatcher";
import Spinner from "../Spinner";

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export default function Input(props: InputInterface) {
    // const [field, setField] = useState(props?.field);
    const [value, setValue] = useState(props?.field?.value)
    const [loading, setLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    let field = props?.field;
    // const value = props?.field?.value
    const isCheckbox = field.type === 'checkbox';
    const isFile = field.type === 'file';
    const isPassword = field.type === 'password';
    const [checkbox, setCheckbox] = useState(true);
    const requestDispatcher = new RequestDispatcher()
    const [file, setFile] = useState(null);

    useEffect(() => {
        setValue(props?.field?.value)
        console.log("input")
    }, [props?.field]);

    async function handleFileChange(e: any) {
        setLoading(true)
        const file = e.target.files[0];
        setFile(file);
        await requestDispatcher.uploadFile(file)
            .then((data) => {
                    if (!data)
                        return
                    field.value = data;
                }
            ).finally(
                () => {
                    setLoading(false);
                    setLoaded(true);
                }
            );
    }

    const toggleCheckbox = () => {
        field.value = !checkbox;
        setCheckbox(!checkbox)
    }

    const isIcon = field.type === 'icon';

    function getUploaderIcon() {
        if (loading)
            return
        return !loaded ? <IoMdCloudUpload color={"#DA291C"} size={48}/> : <IoMdCloudDone color={"#DA291C"} size={48}/>;
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {isIcon && (
                <div style={{display: 'flex', placeContent: 'center'}}>
                    <Button component="label"
                            role={undefined}
                            variant="text"
                    >
                        <Avatar style={{height: 100, width: 100}} alt="User profile photo." src={value?.toString()}/>
                        <VisuallyHiddenInput
                            accept="application/pdf"
                            type="file"
                            onChange={(event) => console.log(event.target.files)}
                            // multiple
                        />
                    </Button>
                </div>
            )}
            {isFile && (
                <>
                    <p style={{margin: 5}}>{field.label}</p>
                    <Button
                        component="label"
                        role={undefined}
                        style={{
                            color: "black",
                            borderColor: "#DA291C",
                            backgroundColor: 'rgba(218,41,28,0.11)',
                            fontFamily: 'Poppins, sans-serif',
                            display: 'flex',
                            height: '150px',
                            flexDirection: 'column',
                            borderStyle: 'dashed'
                        }}
                        variant="outlined"
                        tabIndex={-1}
                        startIcon={getUploaderIcon()}
                    >

                        {loading && <Spinner></Spinner>}
                        {!loading && !loaded && <>
                            <p style={{color: "#DA291C"}}>{field.placeholder}</p>
                            <VisuallyHiddenInput
                                type="file"
                                onChange={handleFileChange}
                                accept={"application/pdf"}
                            /></>
                        }
                    </Button>
                </>)}
            {!isCheckbox && !isFile && !isIcon &&
                <>
                    <p style={{margin: 5}}>{field.label}</p>
                    <input className={styles.input}
                           name={field.label}
                           value={value?.toString()}
                           type={field.type}
                           onChange={(event) => {
                               field.value = event.target.value;
                               setValue(event.target.value)
                           }}
                           placeholder={field.placeholder}/>
                    {isPassword && <a href={field.actionRedirect} style={{
                        fontWeight: 'bold',
                        fontSize: 10,
                        textDecoration: "underline",
                        color: 'black',
                        padding: '6px 0 0 0',
                        alignSelf: 'end'
                    }}>{field.actionLabel}</a>}
                </>
            }
            {isCheckbox &&
                <>
                    <div style={{display: "flex", gap: 12, alignItems: 'center', padding: '1% 0'}}
                         onClick={toggleCheckbox}>
                        {checkbox ? <FaCheckCircle size={18}/> : <FaRegCircle size={18}/>}
                        <p style={{fontSize: 12, margin: 0}}>{field.label}</p>
                    </div>
                </>
            }
        </div>
    )
}

//
// ( field.type == 'checkbox' &&
//
// )