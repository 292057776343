import styles from './index.module.css'
import {CustomForm} from "../../../../components/CustomForm";
import * as React from "react";
import {Form} from "../../../../components/CustomForm/models";
import MenuDispatcher from "../../../../utils/Dispatchers/Menu/Desk/MenuDispatcher";
import {formToObject} from "../../../../utils/Json";
import {paths} from "../../../paths";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";


export default function CreateDeskMenuPage() {
    const navigate = useNavigate();

    const form: Form = {
        name: 'Novo cardápio',
        fields: [
            {name: 'name', type: 'text', label: 'Título', placeholder: 'Título', value: undefined},
            {
                name: 'description',
                type: 'text',
                label: 'Descrição',
                placeholder: 'Descrição do cardápio',
                value: undefined
            },
            {
                name: 'file_url',
                type: 'file',
                label: 'Suba o arquivo do seu cardápio',
                placeholder: 'Selecionar',
                value: undefined
            }
        ],
        buttonLabel: "Criar"
    };

    const menuDispatcher = new MenuDispatcher();

    function createDeskMenu() {
        let body: any = formToObject(form);
        menuDispatcher.create(body)
            .then(
                (data: any) => {
                    navigate(paths.menus + data?.token + '/')
                    toast.success('Cardápio cadastrado com sucesso!')
                },
                err => {
                    toast.error('Ocorreu um erro. Por favor, verifique os campos e tente novamente.')
                }
            );
    }

    return (
        <section className={styles.menusContainer}>
            <h1>Novo cardápio</h1>
            <CustomForm form={form} formAction={createDeskMenu}></CustomForm>
        </section>
    );
};
