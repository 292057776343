import styles from './index.module.css'
import {useEffect, useState} from "react";
import {CustomForm} from "../../components/CustomForm";
import {paths} from "../paths";
import {formToObject} from "../../utils/Json";
import UserDispatcher from "../../utils/Dispatchers/UserDispatch";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useUserStore} from "../../zustand.store";
import {User} from "../../interfaces/user";


export default function UserPage() {
    const userDispatcher = new UserDispatcher();
    const {token} = useParams()
    const {loggedUser, updateUser} = useUserStore();
    const [user, setUser] = useState(loggedUser);
    const [form, setForm] = useState({...formInitialState(loggedUser)});

    function formInitialState(user: User) {
        return {
            name: 'Minha conta',
            fields: [
                {name: 'photo_url', type: 'icon', value: user?.photo_url},
                {name: 'first_name', type: 'text', label: 'Nome', value: user?.first_name},
                {name: 'last_name', type: 'text', label: 'Sobrenome', value: user?.last_name},
                {name: 'phone_number', type: 'text', label: 'Telefone', value: user?.phone_number},
                {
                    name: 'password',
                    type: 'password',
                    label: 'Senha',
                    value: '*********',
                    actionLabel: 'Alterar senha',
                    actionRedirect: paths.forgotPassword
                },
            ],
            buttonLabel: 'Salvar alterações'
        };
    }


    useEffect(() => {
        getUser()
    }, []);

    function getUser() {
        userDispatcher.get(token)
            .then(
                data => {
                    console.log("DATA")
                    console.log(data)
                    setUser(data);
                    updateUser(data);
                    setForm({...formInitialState(data)})
                    console.log("FORM ATUALIZADO", form);
                },
                err => {
                    toast.warning('Ocorreu um erro. Por favor recarregue a página e tente novamente.')
                }
            );
    }

    const changeUser = () => {
        if (!token)
            return
        let body: any = formToObject({...form});
        userDispatcher.update(token, body)
            .then(
                data => {
                    toast.success('Usuário atualizado com sucesso.')
                },
                err => {
                    toast.warning('Ocorreu um erro. Por favor, revise os campos e tente novamente.')
                }
            );
    }


    return (
        <section className={styles.contentContainer}>
            <div className={styles.cardContainer}>
                <h1>Minha conta</h1>
                <CustomForm form={form} formAction={changeUser}></CustomForm>
            </div>
        </section>
    );
};
