import styles from './index.module.css'
import {MutableRefObject, useRef, useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {FaChevronLeft, FaLink} from "react-icons/fa6";
import {QRCode} from 'react-qrcode-logo';
import Button from "@mui/material/Button";
import {Switch} from "@mui/material";
import {CiTrash} from "react-icons/ci";
import {MdOpenInNew} from "react-icons/md";
import {toast} from "react-toastify";
import {Menu} from "../../interfaces/menu";
import MenuDispatcher from "../../utils/Dispatchers/Menu/Desk/MenuDispatcher";
import {paths} from "../paths";


export default function SingleMenuPage() {
    const {token} = useParams();
    const qrRef = useRef<QRCode>()
    const publicLink = {inputProps: {'aria-label': 'Switch public link'}};
    const navigate = useNavigate();
    const [menu, setMenu] = useState<Menu>()
    const [checked, setChecked] = useState(false)
    const menuDispatcher = new MenuDispatcher();

    useEffect(() => {
        getMenu()
    }, []);

    function getMenu() {
        // return newMenu();
        if (!token)
            return
        menuDispatcher.getMenu(token).then(
            (data) => {
                setMenu(data);
                setChecked(data?.active ? data.active : false)
            },
            err => {
                toast.warning('Ocorreu um erro. Por favor, recarregue a página ou tente novamente.');
            }
        )
    }


    function copy() {
        if (!menu)
            return
        navigator.clipboard.writeText(menu.file_url);
        toast.success('Copiado para a área de transferência.')
    }


    function previousPage() {
        navigate(-1)
    }

    function toggleSwitch(event: React.ChangeEvent<HTMLInputElement>) {
        if (!menu)
            return
        setChecked(event.target.checked);
        menu.active = event.target.checked;
        menuDispatcher.changeActive(menu?.token, event.target.checked)
            .then(data => {
                console.log("toggleSwitch data: ", data)
                return data
            })
            .catch(error => {
                return error
            })
    }

    const deleteMenu = () => {
        if (!menu)
            return
        menuDispatcher.remove(menu?.token)
            .then(data => {
                toast.success('Cardápio excluído com sucesso!')
                navigate(paths.deskMenu)
            })
            .catch(error => {
                toast.error('Não foi possível remover o cardápio. Por favor, tente novamente.')
                return error
            })
    };

    function openFile() {
        if (!menu)
            return
        window.open(menu.file_url, "_blank")
    }

    return (
        <section className={styles.pageContainer}>
            <div className={styles.contentContainer}>
                <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                    <FaChevronLeft onClick={previousPage}/>
                    <h1>Cardápios de Mesa</h1>
                </div>
            </div>
            <div className={styles.cardContainer}>
                {menu &&
                    <div className={styles.cardContent}>
                        <div className={styles.content}>
                            <div style={{
                                borderRadius: 4, padding: 8,
                                backgroundColor: '#F0F0F0',
                                // backgroundColor: 'rgba(218, 41, 28, 0.10)'
                            }}>
                                <QRCode ref={qrRef as MutableRefObject<QRCode>} size={180} bgColor={'transparent'}
                                        logoImage="https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/IFood_logo.svg/1280px-IFood_logo.svg.png"
                                        ecLevel={'H'}
                                        enableCORS={true}
                                        logoWidth={80}
                                        logoHeight={50}
                                        removeQrCodeBehindLogo={true}
                                        qrStyle={"dots"}
                                        logoPaddingStyle={"square"}
                                        value={menu?.file_url}/>
                            </div>
                            <Button component="label"
                                    fullWidth={true}
                                    style={{
                                        height: 45,
                                        fontFamily: 'Poppins',
                                        // backgroundColor: 'rgba(218, 41, 28, 0.15)',
                                        backgroundColor: '#F0F0F0',
                                        color: "#da291c",
                                        borderRadius: 4
                                    }}
                                    variant="contained"
                                    role={undefined}
                                    onClick={() => {
                                        qrRef?.current?.download()
                                    }}
                            >Imprimir</Button>
                        </div>

                        <div style={{
                            width: '100%',
                            backgroundColor: '#fff'
                        }}>
                            <div>
                                <div className={styles.headerContent}>
                                    <h1>#{menu?.open_id} {menu?.name}</h1>
                                    <div className={styles.headerActions}>
                                        <Button style={{color: 'black'}}><CiTrash onClick={deleteMenu}
                                                                                  size={24}/></Button>
                                        {/*<Button style={{color: 'black'}}><CiEdit size={24}/></Button>*/}
                                        <Button style={{color: 'black'}}><MdOpenInNew onClick={openFile}
                                                                                      size={24}/></Button>
                                    </div>
                                </div>
                                <h3 style={{color: '#666666', fontWeight: 'normal'}}>{menu.description}</h3>
                                <p style={{color: '#666666', fontWeight: '300', fontSize: 10}}>CRIADO
                                    EM {new Date(menu?.created_at)?.toLocaleDateString()} -
                                    POR {menu?.created_by?.email?.toUpperCase()}</p>
                            </div>
                            <section>
                                <div style={{display: 'flex', gap: 4, alignItems: 'center', padding: '12px'}}>
                                    <FaLink></FaLink>
                                    <p>Link disponível para o público</p>
                                    <Switch
                                        checked={checked}
                                        inputProps={{'aria-label': 'controlled'}}
                                        onChange={toggleSwitch}
                                        size="small"/>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{
                                        backgroundColor: '#F0F0F0',
                                        height: 30,
                                        display: "flex",
                                        alignItems: 'center',
                                        padding: 8,
                                        borderRadius: '10px 0px 0px 10px'
                                    }}>
                                        <p className={styles.menuUrl}>{menu.file_url?.split('?')[0]}</p>
                                    </div>
                                    <Button onClick={copy} style={{
                                        borderRadius: '0px 10px 10px 0px',
                                        backgroundColor: '#F0F0F0',
                                        // backgroundColor: 'rgba(218, 41, 28, 0.13)',
                                        color: '#DA291C',
                                        border: 'solid #DA291C',
                                        height: 45,
                                        padding: 5,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <p style={{fontSize: 14}}>Copiar</p>
                                    </Button>
                                </div>
                            </section>
                        </div>
                    </div>}
            </div>
        </section>
    );
};
